/* styles.css */

.ikexHN {
    font-size: 15px !important;
}

.user_register_title_main {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}


/* Apply table styling for small devices (width < 768px) */

@media (max-width: 767px) {
    .table-responsive-sm {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .table-responsive-sm>.table {
        margin-bottom: 0;
    }
    .table-responsive-sm>.table>thead>tr>th,
    .table-responsive-sm>.table>tbody>tr>th,
    .table-responsive-sm>.table>tfoot>tr>th,
    .table-responsive-sm>.table>thead>tr>td,
    .table-responsive-sm>.table>tbody>tr>td,
    .table-responsive-sm>.table>tfoot>tr>td {
        white-space: nowrap;
    }
}