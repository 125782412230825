.bg-image-vertical {
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: auto 100%;
}

.login-left-col {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-title-left {
    font-family: 'Poppins', sans-serif;
    color: #fff;
    font-weight: 600;
    font-size: 42px;
    text-transform: uppercase;
}

.login-right-title {
    font-family: 'Poppins', sans-serif;
    color: #212121;
    font-weight: 600;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
}

.login_label {
    font-family: 'Poppins', sans-serif;
    color: #212121;
    font-weight: 600;
}

.error-text {
    font-family: 'Poppins', sans-serif;
    color: #f90a0a;
    font-weight: 400;
}

.login-input {
    border-radius: 2px !important;
    height: 40px;
    display: block;
    width: 100%;
    border: 1px solid #d1d1d1;
    outline: none;
    padding: 0rem 1rem;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.login-main {
    background-image: url('../assets/image/login-bg.avif');
    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.login-sub-sec {
    display: flex;
    height: 100vh;
    width: 100%;
    justify-content: center;
    background: linear-gradient(268.86deg, rgba(0, 0, 0, 0.6) 40.2%, rgba(0, 0, 0, 0.6) 114.1%);
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.login-card {
    width: 50% !important;
    background: #fff !important;
    padding: 2rem 0rem;
}

.login-btn {
    width: 100% !important;
    background-color: #212529 !important;
    color: #fff !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600 !important;
    border-radius: 2px !important;
    height: 40px !important;
}

.password_sec {
    position: relative;
}

.password_icon_sec {
    position: absolute;
    top: 54%;
    right: 5%;
}


/* .login-title-sec h1 {
    padding: 0rem 10rem;
} */

@media (min-width:1300px) and (max-width:1500px) {
    .login-card {
        width: 60% !important;
        background: #fff !important;
        padding: 2rem;
    }
}

@media (min-width: 1025px) {
    .h-custom-2 {
        height: 100%;
    }
}

@media (max-width: 1000px) {
    .login-sub-sec {
        margin: 0 !important;
    }
    .login-card {
        width: 100% !important;
    }
    .login-title-sec h1 {
        padding: 0 !important;
        font-size: 24px !important;
    }
    .login-left-col {
        display: none;
    }
}