.admin_main_section {
    padding: 3rem 0rem 0rem 0rem;
    height: calc(100vh - 80px);
}

.homes_cards_main {
    border: 1px solid #80808026;
    box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.38);
    border-radius: 5px;
    padding: 1rem;
    height: 185px;
    position: relative;
}

.count_card_table {
    /* border: 1px solid #80808026;
    box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.38);
    border-radius: 5px; */
    padding: 1rem;
    height: 100%;
    width: 80%;
}

.table_top_title {
    text-align: center;
    margin: 1rem 0rem;
}

.table_top_title_span {
    font-size: 20px;
    font-weight: 600;
    /* font-family: monospace; */
    font-family: 'Poppins', sans-serif;
    color: #212121;
}

.user_count_sec {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.count_table_tr th,
.count_table_tr td {
    font-family: 'Poppins', sans-serif;
    color: #212121;
    border: 1px solid gray;
    border-collapse: collapse;
    /* text-align: center; */
}

.user_count_sec_h1 {
    font-size: 26px;
    font-weight: 600;
    /* font-family: monospace; */
    font-family: 'Poppins', sans-serif;
    color: #212121;
}

.user_count_sec_button {
    border: none;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    /* height: 35px; */
    padding: 0.4rem 2rem;
    background-color: #8a2be2bd;
    color: #fff;
    border-radius: 3px;
    text-decoration: none;
}

.count_in_number_sec {
    position: absolute;
    bottom: 12%;
    right: 3%;
}

.number_count_h1 {
    font-size: 26px;
    font-weight: 600;
    /* font-family: monospace; */
    font-family: 'Poppins', sans-serif;
    color: #212121;
}